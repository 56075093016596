<template>
  <div>
    <div v-if="displayLoading">
      <Loading />
    </div>
    <div v-if="isChecked">
      <img
        v-if="getModuleConfig('page_kv_image')"
        :src="getModuleConfig('page_kv_image')"
        class="w-100"
      />
      <div class="page">

        <div class="page__title">
          {{ getModuleConfig("verify_form_page.form_title") }}
        </div>
        <div
          class="page__desc"
          v-html="getModuleConfig('verify_form_page.form_desc')"
        ></div>

        <div class="form">
          <div class="field">
            <div>
              <label
                class="field__label field--required"
                >{{ getModuleConfig('verify_form_page.field.title') }}</label
              >
              <input
                class="field__input"
                v-model="form.mobile_phone"
                :class="{ invalid: v$.form.mobile_phone.$error }"
                :placeholder="getModuleConfig('verify_form_page.field.placeholder')"
              />
              <small v-if="getModuleConfig('verify_form_page.field.memo')" class="field__desc">
              {{ getModuleConfig('verify_form_page.field.memo') }}
              </small>
              <div
                v-if="v$.form.mobile_phone.$error"
                class="form__error-msg"
              >
                輸入的資料有誤或未填寫，請確認
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="reminder"
        v-html="getModuleConfig('verify_form_page.reminder')"
      ></div>
      <div
        class="d-flex"
        v-if="
          getModuleConfig('form_page.enable_terms_check') &&
          getModuleConfig('form_page.terms_check')
        "
        style="margin-left: 12px"
      >
        <b-form-checkbox
          class="my-auto ml-2"
          v-model="termCheck"
        ></b-form-checkbox>
        <div
          class="terms_check"
          v-html="getModuleConfig('form_page.terms_check')"
        ></div>
      </div>
      <div class="page__button">
        <SharedButton
          class="s-btn-bg-primary"
          @click="handleSubmit"
          :disabled="!termCheck"
        >
          送出
        </SharedButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import registerApi from "@/apis/liff/v2/register";
import dateOptionMixin from "@/mixins/liff/dateOption";
import registerMixin from "@/mixins/liff/register";
import Loading from "@/components/Page/Liff/Shared/Loading";

const TIMEOUT = 120;

export default {
  mixins: [dateOptionMixin, registerMixin],
  components: {
    SharedButton,
    Loading,
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      isChecked: true,
      form: {
        mobile_phone: null,
      },
      displayLoading: false,
      termCheck: true,
      providerOptions: {},
      verificationCode: '',
      counter: TIMEOUT,
      timer: null,
      codeIsValid: true,
      mobileIsValid: false,
      isMobileValidating: false,
      mobileValidMessage: null,
      codeValidMessage: null,
    };
  },

  computed: {
    ...mapGetters("liffAuth", ["user"]),
  },

  validations() {
    let form = {};

    form.mobile_phone = {
      required,
      mobile: (val) => {
        return /09[0-9]{8}/.test(val);
      },
    };

    return {
      form,
    };
  },

  created() {
    this.init();
  },

  mounted() {},

  methods: {
    async init() {
      // this.displayLoading = true;
      // let result = await this.checkRegistered();

      // if (result === false) {
      //   return;
      // } else {
      //   this.displayLoading = false;
      // }

      // if (
      //   this.getModuleConfig("terms_page.enable") &&
      //   localStorage.getItem("register.checkTime") == null
      // ) {
      //   this.$router.push({
      //     name: "LiffRegisterTerms",
      //     params: {
      //       orgCode: this.$route.params.orgCode,
      //     },
      //     query: this.$route.query,
      //   });

      //   return;
      // } else {
      //   this.isChecked = true;
      // }
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }

      if (!this.termCheck) {
        return;
      }

      try {
        this.displayLoading = true;
        await this.doHandleSubmit();

        this.$store.commit("liffAuth/SET_MOBILE", this.form.mobile_phone);
        this.displayLoading = false;
        if (this.getModuleConfig("verify_mobile.enable")) {
          this.$router.push({
            name: "LiffRegisterValidate",
            params: {
              orgCode: this.$route.params.orgCode,
            },
            query: this.$route.query,
          });
        } else {
          this.$router.push({
            name: "LiffRegisterWelcome",
            params: {
              orgCode: this.$route.params.orgCode,
            },
            query: this.$route.query,
          });
        }
      } catch (error) {
        this.displayLoading = false;
        console.error("error", error);
      }
    },
    async doHandleSubmit() {
      const payload = {
        mobile_phone: this.form.mobile_phone,
      };

      await registerApi.onlyVerify(payload);
      await this.fetchMe();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/shared/components/_fields.scss';

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.reminder {
  font-size: 12px;
  color: var(--liff-secondary_text_color);
  margin: 12px;
  a {
    text-decoration: underline;
  }
}

.terms_check {
  color: var(--liff-primary_text_color);
  margin: 12px;
  font-size: 16px;
  ::v-deep a {
    text-decoration: underline;
    color: var(--s-primary);
  }
}

.code-input {
  margin-bottom: 32px;
  ::v-deep .react-code-input-container {
    margin: auto;
    text-align: center;
    width: auto !important;
    &.invalid input {
      border: 1px solid #fe0000;
    }
  }
  ::v-deep input {
    border: 1px solid #636366 !important;
    border-radius: 10px !important;
    font-size: 18px;
    font-weight: 600;
    width: 14% !important;
    max-width: 45px;
    font-family: PingFang TC;
    color: var(--primary-color) !important;

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    &:focus {
      border: 1px solid var(--liff-primary_text_color);
      caret-color: var(--liff-primary_text_color);
    }
    &:focus + input {
      border: 1px solid #636366;
    }
  }
}
</style>
